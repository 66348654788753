var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "py-4",
      staticStyle: { position: "relative" },
      style: _vm.backCss,
    },
    [
      _c("a-spin", { attrs: { spinning: _vm.loading } }, [
        _c("div", { staticClass: "actions flex jc-end mb-4" }, [
          _c("div", { staticStyle: { flex: "1" } }, [
            _c(
              "div",
              { staticStyle: { width: "800px" } },
              [
                _c(
                  "a-row",
                  { attrs: { type: "flex", gutter: 16 } },
                  [
                    _c(
                      "a-col",
                      [
                        _c("a-input", {
                          attrs: {
                            disabled: _vm.selectData,
                            placeholder: "请输入原文名或译文名",
                          },
                          on: { pressEnter: _vm.searchQuery },
                          model: {
                            value: _vm.searchText,
                            callback: function ($$v) {
                              _vm.searchText = $$v
                            },
                            expression: "searchText",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              disabled: _vm.selectData,
                              type: "primary",
                              icon: "search",
                            },
                            on: { click: _vm.searchQuery },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.echartsShow && _vm.selectData && _vm.showAction
            ? _c(
                "div",
                { staticClass: "slideer-button" },
                [
                  _c(
                    "a-tooltip",
                    { attrs: { transitionName: "", placement: "left" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [
                          _vm._v("操作小tips："),
                          _c("br"),
                          _vm._v("单击鼠标左键查看对应术语关联的数据"),
                          _c("br"),
                          _vm._v("单击右键查看术语详情"),
                        ]),
                      ]),
                      _c("a-button", {
                        staticStyle: { "margin-bottom": "5px" },
                        attrs: {
                          shape: "circle",
                          size: "small",
                          icon: "question",
                        },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "a-tooltip",
                    { attrs: { transitionName: "", placement: "left" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [_vm._v("刷新")]),
                      ]),
                      _c("a-button", {
                        staticStyle: { "margin-bottom": "5px" },
                        attrs: { shape: "circle", size: "small", icon: "redo" },
                        on: { click: _vm.getAllChartData },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "a-tooltip",
                    { attrs: { transitionName: "", placement: "left" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [_vm._v("回到原点")]),
                      ]),
                      _c(
                        "a-button",
                        {
                          staticClass: "resize-button",
                          staticStyle: { "margin-bottom": "5px" },
                          attrs: { shape: "circle", size: "small" },
                          on: { click: _vm.rePosition },
                        },
                        [_c("div", { staticClass: "resize" })]
                      ),
                    ],
                    2
                  ),
                  _c(
                    "a-tooltip",
                    { attrs: { transitionName: "", placement: "left" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [_vm._v("放大")]),
                      ]),
                      _c("a-button", {
                        staticStyle: { "margin-bottom": "5px" },
                        attrs: { shape: "circle", size: "small", icon: "plus" },
                        on: { click: _vm.add },
                      }),
                    ],
                    2
                  ),
                  _c("a-slider", {
                    staticStyle: { "z-index": "1" },
                    attrs: { vertical: "", max: 200, min: 10 },
                    on: { change: _vm.sliderChange },
                    model: {
                      value: _vm.sliderNum,
                      callback: function ($$v) {
                        _vm.sliderNum = $$v
                      },
                      expression: "sliderNum",
                    },
                  }),
                  _c(
                    "a-tooltip",
                    { attrs: { transitionName: "", placement: "left" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [_vm._v("缩小")]),
                      ]),
                      _c("a-button", {
                        staticStyle: { "margin-top": "5px" },
                        attrs: {
                          disabled: _vm.scaleNum < 0.2,
                          shape: "circle",
                          size: "small",
                          icon: "minus",
                        },
                        on: { click: _vm.sub },
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm.echartsShow
          ? _c("div", {
              staticStyle: { width: "100%", height: "490px" },
              attrs: { id: "box" },
              on: {
                contextmenu: function ($event) {
                  $event.preventDefault()
                },
              },
            })
          : _vm._e(),
        !_vm.selectData && _vm.echartsShow
          ? _c("div", { staticClass: "echarts-view" }, [
              _vm.copyData.filter((item) => item.termTypeName == "主角").length
                ? _c("div", { staticClass: "echarts-view-title" }, [
                    _vm._v("主角"),
                  ])
                : _vm._e(),
              _vm.copyData.filter((item) => item.termTypeName == "主角").length
                ? _c(
                    "div",
                    { staticClass: "echarts-view-content" },
                    _vm._l(
                      _vm.copyData.filter(
                        (item) => item.termTypeName == "主角"
                      ),
                      function (item) {
                        return _c(
                          "a-popover",
                          { key: item.id, attrs: { placement: "right" } },
                          [
                            _c("template", { slot: "content" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "250px",
                                    "padding-left": "10px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("原文名：")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "45%",
                                            "white-space": "pre-line",
                                            "word-wrap": "break-word",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.originName))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("译文名：")]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "45%" } },
                                        [_vm._v(_vm._s(item.processedName))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("自称：")]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "45%" } },
                                        [_vm._v(_vm._s(item.call))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("语气：")]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "45%" } },
                                        [_vm._v(_vm._s(item.tone || ""))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("略称（小名）：")]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "45%" } },
                                        [_vm._v(_vm._s(item.originNickname))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("身份（职业）：")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "45%",
                                            "white-space": "wrap",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.identity))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("说明：")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "45%",
                                            "white-space": "wrap",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.description))]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "echarts-view-content-item",
                                on: {
                                  contextmenu: function ($event) {
                                    $event.preventDefault()
                                    return _vm.nodeDBClick({
                                      data: item,
                                      dataType: "node",
                                    })
                                  },
                                  click: function ($event) {
                                    return _vm.nodeClick({
                                      data: item,
                                      dataType: "node",
                                    })
                                  },
                                },
                              },
                              [
                                _c("div", [
                                  _c("img", {
                                    style:
                                      item.gender == "女"
                                        ? "border:2px solid #FF6680"
                                        : item.gender == "男"
                                        ? "border:2px solid #3479F7"
                                        : "",
                                    attrs: { src: item.subjectImgUrl },
                                  }),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "echarts-view-conten-item-name",
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                              ]
                            ),
                          ],
                          2
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _vm.copyData.filter((item) => item.termTypeName == "配角").length
                ? _c("div", { staticClass: "echarts-view-title" }, [
                    _vm._v("配角"),
                  ])
                : _vm._e(),
              _vm.copyData.filter((item) => item.termTypeName == "配角").length
                ? _c(
                    "div",
                    { staticClass: "echarts-view-content" },
                    _vm._l(
                      _vm.copyData.filter(
                        (item) => item.termTypeName == "配角"
                      ),
                      function (item) {
                        return _c(
                          "a-popover",
                          { key: item.id, attrs: { placement: "right" } },
                          [
                            _c("template", { slot: "content" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "250px",
                                    "padding-left": "10px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("原文名：")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "45%",
                                            "white-space": "pre-line",
                                            "word-wrap": "break-word",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.originName))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("译文名：")]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "45%" } },
                                        [_vm._v(_vm._s(item.processedName))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("自称：")]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "45%" } },
                                        [_vm._v(_vm._s(item.call))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("语气：")]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "45%" } },
                                        [_vm._v(_vm._s(item.tone || ""))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("略称（小名）：")]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "45%" } },
                                        [_vm._v(_vm._s(item.originNickname))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("身份（职业）：")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "45%",
                                            "white-space": "wrap",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.identity))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("说明：")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "45%",
                                            "white-space": "wrap",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.description))]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "echarts-view-content-item",
                                on: {
                                  contextmenu: function ($event) {
                                    $event.preventDefault()
                                    return _vm.nodeDBClick({
                                      data: item,
                                      dataType: "node",
                                    })
                                  },
                                  click: function ($event) {
                                    return _vm.nodeClick({
                                      data: item,
                                      dataType: "node",
                                    })
                                  },
                                },
                              },
                              [
                                _c("div", [
                                  _c("img", {
                                    style:
                                      item.gender == "女"
                                        ? "border:2px solid #FF6680"
                                        : item.gender == "男"
                                        ? "border:2px solid #3479F7"
                                        : "",
                                    attrs: { src: item.subjectImgUrl },
                                  }),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "echarts-view-conten-item-name",
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                              ]
                            ),
                          ],
                          2
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _vm.copyData.filter((item) => item.termTypeName == "路人").length
                ? _c("div", { staticClass: "echarts-view-title" }, [
                    _vm._v("路人"),
                  ])
                : _vm._e(),
              _vm.copyData.filter((item) => item.termTypeName == "路人").length
                ? _c(
                    "div",
                    { staticClass: "echarts-view-content" },
                    _vm._l(
                      _vm.copyData.filter(
                        (item) => item.termTypeName == "路人"
                      ),
                      function (item) {
                        return _c(
                          "a-popover",
                          { key: item.id, attrs: { placement: "right" } },
                          [
                            _c("template", { slot: "content" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "250px",
                                    "padding-left": "10px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("原文名：")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "45%",
                                            "white-space": "pre-line",
                                            "word-wrap": "break-word",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.originName))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("译文名：")]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "45%" } },
                                        [_vm._v(_vm._s(item.processedName))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("自称：")]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "45%" } },
                                        [_vm._v(_vm._s(item.call))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("语气：")]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "45%" } },
                                        [_vm._v(_vm._s(item.tone || ""))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("略称（小名）：")]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "45%" } },
                                        [_vm._v(_vm._s(item.originNickname))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("身份（职业）：")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "45%",
                                            "white-space": "wrap",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.identity))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("说明：")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "45%",
                                            "white-space": "wrap",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.description))]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "echarts-view-content-item",
                                on: {
                                  contextmenu: function ($event) {
                                    $event.preventDefault()
                                    return _vm.nodeDBClick({
                                      data: item,
                                      dataType: "node",
                                    })
                                  },
                                  click: function ($event) {
                                    return _vm.nodeClick({
                                      data: item,
                                      dataType: "node",
                                    })
                                  },
                                },
                              },
                              [
                                _c("div", [
                                  _c("img", {
                                    style:
                                      item.gender == "女"
                                        ? "border:2px solid #FF6680"
                                        : item.gender == "男"
                                        ? "border:2px solid #3479F7"
                                        : "",
                                    attrs: { src: item.subjectImgUrl },
                                  }),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "echarts-view-conten-item-name",
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                              ]
                            ),
                          ],
                          2
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _vm.copyData.filter((item) => item.termTypeName == "团体").length
                ? _c("div", { staticClass: "echarts-view-title" }, [
                    _vm._v("团体"),
                  ])
                : _vm._e(),
              _vm.copyData.filter((item) => item.termTypeName == "团体").length
                ? _c(
                    "div",
                    { staticClass: "echarts-view-content" },
                    _vm._l(
                      _vm.copyData.filter(
                        (item) => item.termTypeName == "团体"
                      ),
                      function (item) {
                        return _c(
                          "a-popover",
                          { key: item.id, attrs: { placement: "right" } },
                          [
                            _c("template", { slot: "content" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "padding-left": "10px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("分类：")]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "45%" } },
                                        [_vm._v(_vm._s(item.termTypeName))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("原文名：")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "45%",
                                            "white-space": "pre-line",
                                            "word-wrap": "break-word",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.originName))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("译文名：")]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "45%" } },
                                        [_vm._v(_vm._s(item.processedName))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("初次登场章节：")]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "45%" } },
                                        [_vm._v(_vm._s(item.debut))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "line-height": "22px",
                                        padding: "5px 0px",
                                        "white-space": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "font-weight": "700",
                                          },
                                        },
                                        [_vm._v("说明：")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "45%",
                                            "white-space": "wrap",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.description || ""))]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "echarts-view-content-item-team",
                                on: {
                                  contextmenu: function ($event) {
                                    $event.preventDefault()
                                    return _vm.nodeDBClick({
                                      data: item,
                                      dataType: "node",
                                    })
                                  },
                                  click: function ($event) {
                                    return _vm.nodeClick({
                                      data: item,
                                      dataType: "node",
                                    })
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "echarts-view-content-item-team-imgs",
                                  },
                                  _vm._l(item.lowers, function (i) {
                                    return _c("img", {
                                      key: i.id + "team",
                                      attrs: {
                                        src:
                                          i.subjectImgUrl ||
                                          "./../../../../assets/tx.png",
                                      },
                                    })
                                  }),
                                  0
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "echarts-view-conten-item-name",
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                              ]
                            ),
                          ],
                          2
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _c("terms-modal", {
        attrs: {
          productionId: _vm.parent.id,
          parent: _vm.parent,
          open: _vm.drawerShow,
          "cur-row": _vm.curRow,
          isForm: false,
        },
        on: { closed: _vm.drawerClosed },
      }),
      _c(
        "a-modal",
        {
          attrs: { title: _vm.teamData.name, width: 450 },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
            _vm._l(_vm.teamData.lowers, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticStyle: {
                    cursor: "pointer",
                    width: "70px",
                    "text-align": "center",
                    "margin-top": "15px",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openTermModal(item.id)
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: {
                      width: "40px",
                      height: "40px",
                      "border-radius": "50%",
                    },
                    attrs: { src: item.subjectImgUrl, alt: "" },
                  }),
                  _c("div", { staticStyle: { "margin-top": "5px" } }, [
                    _vm._v(_vm._s(item.processedName)),
                  ]),
                  item.fromOtherInfo
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "3px",
                            "font-size": "12px",
                          },
                        },
                        [_vm._v(_vm._s(item.fromOtherInfo.call))]
                      )
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
                _vm._v("\n        关闭\n      "),
              ]),
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleOk },
                },
                [_vm._v("\n        编辑团队\n      ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }